import { sliceWalletAddress } from '../pages/customer-details/sliceWalletAddress';

export const unique = (arr) => [...new Set(arr)];

export const valueExists = (value) => value !== undefined && value !== null && value !== '';

export const chunkArray = (arr, chunkSize) => {
  return arr.reduce((result, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!result[chunkIndex]) {
      result[chunkIndex] = [];
    }
    result[chunkIndex].push(item);
    return result;
  }, []);
};

export const getDescriptionInfoFromAccount = (accountData, maxLength = 20) => {
  let tooltipDescription = accountData?.wallet;
  let shortDescription = sliceWalletAddress(tooltipDescription, 4);
  if (valueExists(accountData?.description)) {
    tooltipDescription = accountData.description;
    if (valueExists(accountData?.wallet)) {
      tooltipDescription += ` (${accountData.wallet})`;
    } else if (valueExists(accountData?.address)) {
      tooltipDescription += ` (${accountData.address})`;
    }
    shortDescription = accountData.description.length > maxLength
      ? `${accountData.description.slice(0, maxLength)}...`
      : accountData.description;
  }
  let copyValue = accountData?.wallet;
  if (!valueExists(copyValue)) {
    copyValue = accountData?.address;
  }
  if (!valueExists(copyValue)) {
    copyValue = accountData?.description;
  }
  return {
    tooltipDescription: tooltipDescription ?? '',
    shortDescription: shortDescription ?? '',
    copyValue: copyValue ?? ''
  };
};

export const ellipsis = (str, length) => {
  if (str.length <= length) {
    return str;
  }
  return `${str.slice(0, length)}...`;
};

export const padWithZeros = (num, maxTotalDigits) => {
  const numStr = num.toString();
  const numZeros = Math.max(0, maxTotalDigits - numStr.length);
  return '0'.repeat(numZeros) + numStr;
};

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
