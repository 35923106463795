import { useState, createContext, useEffect, useCallback } from 'react';
import * as Sentry from '@sentry/react';
import authService from '../../services/auth-services/auth.service';
import jwt_token from 'jwt-decode';
import { DEFAULT_ROLE } from '../../utils/roles';
import useCombinedConfig from '../../components/config/Config';
import axios from 'axios';
import { axiosErrorHandler, getStaticPath } from '../../services/api/axios';
import { DEFAULT_CURRENCY } from '../../utils/project-currencies';
import { toast } from 'react-hot-toast';

export const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [userEmail, setUserEmail] = useState(null);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [resendEmail, setResendEmail] = useState(null);
  const [resendSlug, setResendSlug] = useState(null);
  const [emailSent, setEmailSent] = useState(false);
  const [providersInfo, setProvidersInfo] = useState([]);
  const [role, setRole] = useState(DEFAULT_ROLE);
  const [companyCurrency, setCompanyCurrency] = useState(DEFAULT_CURRENCY);

  const { getCombinedConfig } = useCombinedConfig(role);

  const getSlugsIfAvailable = (error) => {
    if (error?.response?.status === 409) {
      const slugs = error.response?.data?.detail;
      return {
        success: false,
        containsSlugs: true,
        response: slugs
      };
    } else {
      toast.error(axiosErrorHandler(error, 'The action failed, please try again later'));
    }
  };

  const checkTokenExpiration = () => {
    let exp = undefined;
    try {
      exp = JSON.parse(localStorage.getItem('user')).exp;
    } catch (error) {
      // leave exp as undefined
    }

    if (exp !== undefined) {
      const date = new Date().getTime();
      if (exp * 1000 - 300000 <= date) {
        performLogout();
      } else {
        return true;
      }
    }

    return false;
  };

  const updateProfile = useCallback(() => {
    return authService.getProfile().then(response => {
      const configVars = ['explorer', 'users_management', 'is_aml_demo', 'add_new_project', 'deposits', 'dashboard', 'my_projects'];
      const responseData = response?.data;
      configVars.forEach(key => {
        if (responseData?.config && key in responseData.config) {
          localStorage.setItem(key, String(responseData?.config[key]));
        }
      });
      setRole(responseData?.role ?? DEFAULT_ROLE);
      setCompanyCurrency(responseData?.currency ?? DEFAULT_CURRENCY);
      return {
        authorized: true,
        email: responseData.email,
        blocked: data.blocked,
      };
    }).catch(reason => {
      if (reason.response.status === 401) {
        return {
          authorized: false,
          email: null,
          blocked: false,
        };
      } else {
        toast.error(axiosErrorHandler(reason, 'Error getting profile'));
      }
      throw reason;
    }).then(data => {
      if (!data.authorized) {
        localStorage.clear();
      }
      setAuthorized(data.authorized);
      setUserEmail(data.email);
      setIsLimitReached(data.blocked);
      setLoading(false);
      if (data.authorized) {
        checkTokenExpiration();
      }
      return data;
    });
  }, [setAuthorized, setUserEmail, setLoading, setIsLimitReached]);

  const getProvidersInfo = () => {
    axios.get(getStaticPath('ICONS_URL')).then((res) => {
      setProvidersInfo(res?.data ?? []);
    }).catch(reason => {
      toast.error(axiosErrorHandler(reason, 'Error getting providers info. Please try again later'));
    });
  };

  useEffect(() => {
    updateProfile().catch(reason => {
      let msg = 'profile get';
      let rt = reason.message;
      let id = Sentry.captureException(Error('Error in ' + msg + ':' + reason + '/' + rt));
      console.log('SentryID: ' + id + ', error: ' + msg + ', reason: ' + reason + '/' + rt);
    });
  }, [updateProfile]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (authorized) {
        checkTokenExpiration();
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [authorized]);

  useEffect(() => {
    getProvidersInfo();
  }, []);

  const performLogin = async (email, password, slug) => {
    try {
      const tokenValue = await authService.login(email, password, slug);
      if (tokenValue) {
        const { exp } = jwt_token(tokenValue);
        localStorage.setItem('user', JSON.stringify({ exp }));
      }
      return { success: true };
    } catch (error) {
      // All errors are handled in getSlugsIfAvailable
      return getSlugsIfAvailable(error);
    }
  };

  const performLogout = async () => {
    try {
      await authService.logout();
    } catch (error) {
      // We are ignoring the result, it should clear the cookie.
    }
    localStorage.clear();
    window.location.reload();
  };

  const performRequestPasswordReset = async (email, slug) => {
    try {
      await authService.requestPasswordReset(email, slug);
      return { success: true };
    } catch (error) {
      // All errors are handled in getSlugsIfAvailable
      return getSlugsIfAvailable(error);
    }
  };

  const performResendEmail = async (email, slug) => {
    try {
      const response = await authService.resendEmail(email, slug);
      return {
        success: true,
        response
      };
    } catch (error) {
      // All errors are handled in getSlugsIfAvailable
      return getSlugsIfAvailable(error);
    }
  };

  const loginGithubWithRedirect = async () => {
    try {
      const data = await authService.loginGithub();
      if (data?.redirect_url) {
        window.location.replace(data.redirect_url);
      }
      return data;
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error logging in using Github. Please try again later'));
    }
  };

  const loginGoogleWithRedirect = async (user) => {
    try {
      const data = await authService.loginGoogle(user);
      if (data?.token_value) {
        const token = data.token_value;
        const { exp } = jwt_token(token);
        localStorage.setItem('user', JSON.stringify({ exp }));
      }
      return data;
    } catch (err) {
      toast.error(axiosErrorHandler(err, 'Error logging in using Google. Please try again later'));
    }
  };
  
  const getConfig = (key) => {
    return getCombinedConfig(key);
  };

  const data = {
    loading,
    authorized,
    userEmail,
    setUserEmail,

    performLogout,
    performLogin,
    performRequestPasswordReset,
    performResendEmail,
    loginGithubWithRedirect,
    loginGoogleWithRedirect,

    emailSent,
    setEmailSent,
    resendEmail,
    setResendEmail,
    resendSlug,
    setResendSlug,
    isLimitReached,

    updateProfile,
    getConfig,

    providersInfo,
    companyCurrency,
  };

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
};

export default UserContextProvider;
